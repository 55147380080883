import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import ProductsTable from '../../components/ProductsTable';
import FilterBar from '../../components/FilterBar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";
import { isUserAuthenticated } from "../../utils/authService";
import addScanedFile from "../../utils/helpers";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function CSVScanner(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({});
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [vlookupObj, setVlookupObj] = useState({});
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [sortASC, setSortASC] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const CATEGORY_BSR_COUNT = {
    'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515,
    'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843,
    'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162,
    'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647,
    'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328,
    'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340,
    'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048,
    'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515,
    'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789,
    'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893,
    'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107,
    'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894,
    "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127,
    "Collectible Coins": 210648, "Camera & Photo": 4021127
  }

  useEffect(() => {
    function calculateROI(item, settings) {
      let logisticsCost = parseFloat(localStorage.getItem("costPrep") || 0);
      let price = item.price * (1 - ((settings.priceCoupon || 0) / 100));
      let azPrice = item.az_price;
      let fbaCost = settings.isFBM ? 0 : item.fba_fee;
      let sellingOnAzFees = item.ref_fee_perc * item.az_price;

      return (azPrice - price - fbaCost - logisticsCost - sellingOnAzFees) / (price + logisticsCost);
    }

    function filterData(data, filters) {
      return data.filter(item => {
        if (parseInt(filters.noVariations || "0") !== 0 && item.variations !== "null") {
          return false;
        }

        // amazonNotSeller
        if (parseInt(filters.amazonNotSeller || "0") !== 0) {
          if (item.az_sells_it) {
            return false;
          }
        }

        // top_bsr
        if (filters.top_bsr && (filters.top_bsr[0] !== -1 || filters.top_bsr[1] !== -1)) {
          let categoryCount = CATEGORY_BSR_COUNT[item.category] || 10000000;
          let salesRankPercentage = (item.sales_rank / categoryCount) * 100;

          if (filters.top_bsr[0] !== -1 && salesRankPercentage < filters.top_bsr[0]) {
            return false;
          }

          if (filters.top_bsr[1] !== -1 && salesRankPercentage > filters.top_bsr[1]) {
            return false;
          }
        }

        // profit
        if (filters.profit && (filters.profit[0] !== -1 || filters.profit[1] !== -1)) {
          let profitValue = item.az_price - (item.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : item.fba_fee) - (item.ref_fee_perc * item.az_price) - parseFloat(localStorage.getItem("costPrep") || 0);

          if (filters.profit[0] !== -1 && profitValue < filters.profit[0]) {
            return false;
          }

          if (filters.profit[1] !== -1 && profitValue > filters.profit[1]) {
            return false;
          }
        }

        // vlookup
        if (filters.vlookup && filters.vlookup !== "") {
          let vlookup = filters.vlookup;
          let idString = '';
          const priceDict = {};
          if (vlookup[0].hasOwnProperty("ASIN")) {
            vlookup.forEach(product => {
              idString += product.ASIN + ',';
              priceDict[product.ASIN] = { "price": product.Price, "source": product?.Source ?? "" };
            });
            if (!idString.includes(item.asin)) {
              return false
            }
          } else if (vlookup[0].hasOwnProperty("Barcode")) {
            vlookup.forEach(product => {
              idString += product.Barcode.replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "") + ',';
              priceDict[product.Barcode.replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "")] = { "price": product.Price, "source": product?.Source ?? "" };
            });
            if (!item.upcs.split(',').some(upc => idString.includes(upc.replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "")))) {
              return false
            }
          }
          setVlookupObj(priceDict);
        }

        // search
        if (filters.search && filters.search !== "") {
          let searchLower = filters.search.toLowerCase();
          if (!item.title.toLowerCase().includes(searchLower) && !item.asin.includes(filters.search)) {
            return false;
          }
        }

        // category
        if (filters.category && filters.category !== "Category" && !filters.category.includes(item.category)) {
          return false;
        }

        // qty, moq, bsr
        for (let filterAttr of ["qty", "moq", "bsr"]) {
          if (filters[filterAttr] && (filters[filterAttr][0] !== -1 || filters[filterAttr][1] !== -1)) {
            let value = item[filterAttr === "bsr" ? "sales_rank" : filterAttr];

            if (filters[filterAttr][0] !== -1 && value < filters[filterAttr][0]) {
              return false;
            }

            if (filters[filterAttr][1] !== -1 && value > filters[filterAttr][1]) {
              return false;
            }
          }
        }

        // price
        if (filters.price && (filters.price[0] !== -1 || filters.price[1] !== -1)) {
          let price = item.price * (1 - ((filters.priceCoupon || 0) / 100));

          if (filters.price[0] !== -1 && price < filters.price[0]) {
            return false;
          }

          if (filters.price[1] !== -1 && price > filters.price[1]) {
            return false;
          }
        }

        // roi
        if (filters.roi && (filters.roi[0] !== -1 || filters.roi[1] !== -1)) {
          let roi = calculateROI(item, filters);

          if (filters.roi[0] !== -1 && roi < (filters.roi[0] / 100)) {
            return false;
          }

          if (filters.roi[1] !== -1 && roi > (filters.roi[1] / 100)) {
            return false;
          }
        }

        // offers
        if (filters.offers && (filters.offers[0] !== -1 || filters.offers[1] !== -1)) {
          let offersCount = item.total_offers_count;

          if (filters.offers[0] !== -1 && offersCount < filters.offers[0]) {
            return false;
          }

          if (filters.offers[1] !== -1 && offersCount > filters.offers[1]) {
            return false;
          }
        }

        // offers
        if (filters.sales && (filters.sales[0] !== -1 || filters.sales[1] !== -1)) {
          let sales = item.keepa_monthly_sold > 0 ? item.keepa_monthly_sold : item.estimate_sales_30_days;

          if (filters.sales[0] !== -1 && sales < filters.sales[0]) {
            return false;
          }

          if (filters.sales[1] !== -1 && sales > filters.sales[1]) {
            return false;
          }
        }

        // offers
        if (filters.bsr_drops_30_days && (filters.bsr_drops_30_days[0] !== -1 || filters.bsr_drops_30_days[1] !== -1)) {
          let drops = item.sales_rank_drops_30_days;

          if (filters.bsr_drops_30_days[0] !== -1 && drops < filters.bsr_drops_30_days[0]) {
            return false;
          }

          if (filters.bsr_drops_30_days[1] !== -1 && drops > filters.bsr_drops_30_days[1]) {
            return false;
          }
        }

        return true;
      });
    }

    const sortFunction = (a, b) => {
      if (filters.sortBy) {
        let getKey = (n, key) => {
          if (filters.sortBy[0] === "roi") {
            return parseFloat(calculateROI(n, filters))
          }
          if (filters.sortBy[0] === "profit") {
            return parseFloat(n.az_price - (n.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : n.fba_fee) - (n.ref_fee_perc * n.az_price) - parseFloat(localStorage.getItem("costPrep") || 0))
          }
          return parseFloat(n[key])
        }
        if (filters.sortBy[1]) {
          return getKey(a, filters.sortBy[0]) - getKey(b, filters.sortBy[0])
        } else {
          return getKey(b, filters.sortBy[0]) - getKey(a, filters.sortBy[0])
        }
      } else {
        return 1
      }
    }

    setFilteredData(filterData(data, filters).sort((a, b) => sortFunction(a, b)));
  }, [filters, data]);

  const capitalizeKeys = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (key.toLocaleLowerCase().includes("barcode") || key.toLocaleLowerCase().includes("upc") || key.toLocaleLowerCase().includes("ean")) {
        acc["Barcode"] = obj[key];
        return acc;
      }
      if (key.toLocaleLowerCase().includes("asin")) {
        acc["ASIN"] = obj[key];
        return acc;
      }
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      acc[capitalizedKey] = obj[key];
      return acc;
    }, {});
  };

  function uploadCSV(file) {
    const lastClickTimestamp = localStorage.getItem('lastClickTimestamp') || -1;
    const minutesSinceLastClick = (Date.now() - parseInt(lastClickTimestamp)) / (1000 * 60);
    if (minutesSinceLastClick < 2 && lastClickTimestamp !== -1) {
      setLoading(false);
      setRefresh(true);
      setModalMsg(`Please wait ${((2 - minutesSinceLastClick) * 60).toFixed(0)} seconds before scanning again!`);
      setOpenModal(true);
      window.location.reload();
      return
    }
    if (file.size > 10000000) {
      setLoading(false);
      setModalMsg("Your file is larger than 10MB. Please consider splitting the Excel file and sending multiple scan requests.");
      setOpenModal(true);
    } else {
      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const fileName = file.name.toLowerCase();
          if (fileName.endsWith('.csv')) {
            const text = e.target.result;
            Papa.parse(text, {
              header: true,
              complete: (results) => {
                const editData = results.data.map((item) => capitalizeKeys(item));
                if (editData.some(item => 'Barcode' in item || 'ASIN' in item)) {
                  localStorage.setItem('lastClickTimestamp', Date.now());
                  addScanedFile(fileName, editData.length, identifier);
                  sendFile(editData);
                } else {
                  setLoading(false);
                  setModalMsg("Your .csv file does not contain a Barcode or ASIN column. Please try again with a new file or download the template file.");
                  setOpenModal(true);
                }
              },
            });
          } else if (fileName.endsWith('.xlsx')) {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const headers = json[0];
            const dataRows = json.slice(1).map((row) => {
              const obj = {};
              headers.forEach((header, index) => {
                obj[header] = row[index];
              });
              return obj;
            });
            const editData = dataRows.map((item) => capitalizeKeys(item));
            if (editData.some(item => 'Barcode' in item || 'ASIN' in item)) {
              localStorage.setItem('lastClickTimestamp', Date.now());
              addScanedFile(fileName, editData.length, identifier);
              sendFile(editData);
            } else {
              setLoading(false);
              setModalMsg("Your .xlsx file does not contain a Barcode or ASIN column. Please try again with a new file or download the template file.");
              setOpenModal(true);
            }
          }
        };

        if (file.name.toLowerCase().endsWith('.csv')) {
          reader.readAsText(file);
        } else if (file.name.toLowerCase().endsWith('.xlsx')) {
          reader.readAsArrayBuffer(file);
        }
      }
    }

    const sendFile = async (data_l) => {
      // Process data_l in batches with delay
      const batchSize = 10;
      let index = 0;

      async function processBatch() {
        if (index < data_l.length) {
          setIsProductsLoading(true);
          const batch = data_l.slice(index, index + batchSize).map(item => ({
            [identifier]: item[[identifier]],
            "Price": item["Price"] ? item["Price"].replace("$", "") : -1,
            "Name": item["Name"],
            "Image": item["Image"]
          }));;

          const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: {
              Accept: "application/json; charset=utf8",
              "Content-Type": "application/json; charset=utf8",
            },
            body: JSON.stringify({
              csv_file: batch,
              identifier: identifier
            }),
          };

          try {
            fetch(" https://server.nepeto.com/upload_scanner_csv_aniseller/", requestOptions)
              .then((response) => response.json())
              .then((data) => {
                if (data === "No permissions") {
                  navigate("/pricing")
                } else {
                  if (data.length > 0 && !props.isMobile) {
                    let filteredData = data
                    if (filteredData.length > 0) {
                      let filteredDataWithHide = filteredData.filter(prod => (localStorage.getItem(prod["asin"].split("/")[prod["asin"].split("/").length - 1] + "NIU") === null || (new Date() > localStorage.getItem(prod["asin"].split("/")[prod["asin"].split("/").length - 1] + "NIU"))))
                      if (filteredDataWithHide.length > 0) {
                        setData((prevData) => [...prevData, ...filteredDataWithHide]);
                        if (index >= data_l.length) {
                          setIsProductsLoading(false);
                        }
                      }
                    }
                  } else {
                    setData((prevData) => [...prevData, ...data])
                    if (index >= data_l.length) {
                      setIsProductsLoading(false);
                    }
                  }
                }
              })
              .catch((e) => {
                setModalMsg("Error! Try again or contact us please.");
                setOpenModal(true);
                setLoading(false);
              });
          } catch (e) {
            setModalMsg("Error! Try again or contact us please.");
            setOpenModal(true);
            setLoading(false);
          }

          index += batchSize;

          // Set timeout for 20 second before processing next batch
          setTimeout(processBatch, 20000); // 20000 milliseconds = 20 second
        } else {
          setIsProductsLoading(false);
        }
      }

      // Start processing batches
      processBatch();
    }
  }

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      setLoading(true);
      uploadCSV(files[0]);
    } else {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setIdentifier(event.target.value);
  };

  const identifierStyle = {
    width: "10vw",
    margin: "auto",
    alignItems: "center",
    textAlign: "center",
    display: "grid",
    fontSize: "150%",
    borderRadius: "100px",
    height: "16vh",
  }
  const activeIdentifierStyle = {
    color: "#7e3e1b",
    cursor: "pointer",
    boxShadow: "0px 0px 5px 0px #7e3e1b",
    backgroundColor: "rgba(126, 62, 27, 0.2)",
  }

  if (!props.isAuth && identifier !== "") {
    navigate('/login');
  }

  return (
    <>
      {data.length === 0 ? (
        <>
          <div style={{ marginBottom: "3.5vh" }}>
            <h1
              style={{
                fontSize: props.isMobile ? "3vh" : "2vw",
                fontWeight: "380",
                textAlign: "center",
                color: "#7e3e1b",
                marginBottom: props.isMobile && "2vh",
              }}
            >
              CSV Scanner
            </h1>
            <h2
              style={{
                fontSize: props.isMobile ? "1.5vh" : "1vw",
                fontWeight: "380",
                textAlign: "center",
                color: "#7e3e1b",
                marginBottom: props.isMobile && "2vh",
              }}
            >
              Your supplier has provided you with a CSV file containing their product listings, and you need to determine which items to buy.<br/>
              Doing this manually, converting UPC codes to ASINs and checking for potential profits, would be time-consuming.<br/>
              The CSV Scanner is here to simplify this process.
            </h2>
            <h2
              style={{
                fontSize: props.isMobile ? "1.5vh" : "1vw",
                fontWeight: "380",
                textAlign: "center",
                color: "rgb(230,50,50)",
                marginBottom: props.isMobile && "2vh",
              }}
            >
              The file should include the following: Identifier Code (UPC/EAN/ASIN), Price, Name (optional), Image (optional).
              <br /><span style={{ fontSize: "75%" }}>* Optional details: Including these can enhance the accuracy of the results.</span>
              {identifier === "Barcode" && <span style={{ fontSize: "95%", color: "green" }}><br />* Note: Matching using UPC/EAN will show all relevant options for you to pick the most suitable one!</span>}
            </h2>
          </div>
          {identifier === "" ? (
            <>
              <div style={{ display: "flex", flexDirection: "row", width: "50vw", margin: "auto", justifyContent: "center", textAlign: "center", backgroundColor: "rgba(200,200,200,0.2)", borderRadius: "30px", marginTop: "1vh", padding: "2vh 1vw 7vh 1vw" }}>
                <h2 style={{ fontSize: "150%", marginBottom: "2vh" }}>Select the identifier for product matching</h2>
                
                <Box sx={{ marginLeft: 10, minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>Identifier</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={identifier}
                      label="Identifier"
                      onChange={handleChange}
                    >
                      <MenuItem value={"ASIN"} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>ASIN</MenuItem>
                      <MenuItem value={"Barcode"} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>UPC / EAN</MenuItem>
                      {/* <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                  </FormControl>
                </Box>
                {/* <div style={{ display: "flex", flexDirection: "row", verticalAlign: "middle", margin: "auto" }}>
                  <div onClick={() => setIdentifier("ASIN")} style={{ ...identifierStyle, ...activeIdentifierStyle, fontSize: "175%", }}>
                    ASIN
                  </div>
                  <div onClick={() => setIdentifier("Barcode")} style={{ ...identifierStyle, ...activeIdentifierStyle, }}>
                    UPC/<br />EAN
                  </div>
                  <div style={{ ...identifierStyle, backgroundColor: "lightgray", color: "#9e9e9e" }}>
                    Name<br />*Soon*
                  </div>
                </div> */}
              </div>
            </>
          ) : (
            <div style={{ width: "50vw", margin: "auto", alignItems: "center", textAlign: "center", backgroundColor: "rgba(200,200,200,0.2)", borderRadius: "30px", marginTop: "1vh", padding: "2vh 1vw 2vh 1vw" }}>
              <h2 style={{ marginBottom: "2vh" }}>Upload the CSV file</h2>
              <h3 style={{ marginBottom: "2vh" }}>Make sure you use our template or use the same column names!</h3>
              <span onClick={() => window.location.href = `data:text/csv;charset=utf-8,${identifier},Price,Name,Image\n`} style={{ color: "slateblue", textDecoration: "underline", cursor: "pointer" }}>Click here to download the template</span>

              <Box sx={{ border: '2px dashed #ccc', padding: '16px', textAlign: 'center', width: '30%', borderRadius: '8px', cursor: 'pointer', margin: '15px auto', }}>
                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} size="large" style={{
                  textTransform: 'none',
                  fontFamily: 'RobotoSlab',
                  color: '#FCF8F3',
                  borderRadius: '10px',
                  backgroundColor: '#7e3e1b',
                  border: '1px solid #FCF8F3',
                }}>
                  Choose a file
                  <VisuallyHiddenInput type="file" onChange={handleFileChange} accept=".csv,.xlsx" />
                </Button>
                <Typography variant="body2" sx={{ marginTop: '8px', fontFamily: 'RobotoSlab', }}>
                  (Accept .csv and .xlsx files)
                </Typography>

                {loading ?
                  <div style={{ color: '#7e3e1b', }}>
                    <LinearProgress sx={{ marginTop: '16px' }} color={'inherit'}/>
                  </div>
                :
                  <Typography variant="caption" sx={{ display: 'block', marginTop: '8px', fontFamily: 'RobotoSlab', }}>
                    No file uploaded
                  </Typography>
                }
              </Box>

            </div>
          )}
        </>
      ) : (
        <>
          <FilterBar
            data={data}
            setFilters={setFilters}
            filters={filters}
            activePlanLevel={props.activePlanLevel}
          />

          <ProductsTable
            data={filteredData}
            setData={setData}
            isMobile={props.isMobile}
            supplierType={"storefront"}
            hideCredits={props.hideCredits}
            showAdv={true}
            isProductsLoading={isProductsLoading}
            filters={filters}
            setFilters={setFilters}
            sortASC={sortASC}
            setSortASC={setSortASC}
            identifier={identifier}
            sellerId={props.sellerId}
            refreshToken={props.refreshToken}
            vlookupObj={vlookupObj}
          />
        </>
      )}

      <Modal open={openModal} onClose={() => {setOpenModal(false); if (refresh) window.location.reload(); if (!loggedIn) navigate('/login'); }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            {modalMsg}
          </Typography>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => {setOpenModal(false); if (refresh) window.location.reload(); if (!loggedIn) navigate('/login'); }} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
            }} >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
export default CSVScanner;
