import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';

function ModalForVariationsTable(props) {
  const [loadedVariations, setLoadedVariations] = useState(-999);

  useEffect(() => {
    const getVariations = async () => {
      fetch("https://server.nepeto.com/get_variations_aniseller/" + props.currAsin + "/")
        .then((response) => response.json())
        .then((data) => {
          if (data === null) {
            setLoadedVariations([]);
          } else {
            setLoadedVariations(data);
          }
        });
    };

    getVariations();
  }, [props, loadedVariations]);

  return (
    <div >
      <Modal open={props.modalForVariations} onClose={() => props.setModalForVariations(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          {/* Title */}
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            Variation Table
          </Typography>

          {/* Table */}
          {loadedVariations !== -999 ? (
            <div className="products-table-container" style={{ maxHeight: '70vh', overflowY: 'auto', }}>
              <table className="products-table">
                <thead>
                  <tr>
                    <th>Asin</th>
                    <th>Attributes</th>
                  </tr>
                </thead>
                <tbody>
                  {loadedVariations.map((product, index) => {
                    const asin = product.asin;
                    return (
                      <tr key={index} style={{ minHeight: '7vh', height: '7vh', backgroundColor: props.currAsin === asin && "rgba(158, 86, 48, 0.2)"}}>
                        <td style={{ width: '50%', padding: 10}}>
                          <div className="amazonImage" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 5, marginBottom: 5, }}>
                            <a href={`https://amazon.com/dp/${asin}?th=1&psc=1`} target="_blank" rel="noreferrer" style={{ verticalAlign: "middle", color: '#7e3e1b', }}>
                              {asin}{asin === props.currAsin ? ' - Current' : ''}
                            </a>
                          </div>
                        </td>
                        <td>
                          {product.attributes.map((attr) => {
                            return <span style={{ fontSize: 16, }}>{attr.dimension}: <strong>{attr.value}</strong><br/></span>
                          })}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
          ) : (
            <Box style={{ display: 'flex', justifyContent: 'center',  }}>
              <CircularProgress style={{ color: '#7e3e1b'}}/>
            </Box>
          )}

        {/* Cancel button */}
        <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
          <Button variant="contained" onClick={() => props.setModalForVariations(false)} style={{
            textTransform: 'none',
            fontFamily: 'RobotoSlab',
            color: '#FCF8F3',
            borderRadius: '20px',
            backgroundColor: '#B33B3B',
            border: '1px solid #FCF8F3'
          }} >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
    </div >
  );
}

export default ModalForVariationsTable;
