import React, { useState } from 'react';
import { Container, Typography, Card, CardContent, Button, Switch, Box, Grid, createTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#7e3e1b',
    '&:hover': {
      backgroundColor: alpha('#7e3e1b', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#7e3e1b',
  },
}));

const Pricing = (props) => {
  const navigate = useNavigate();

  const handleToggle = () => {
    props.setIsYearly(!props.isYearly);
  };

  const price = props.isYearly ? '89' : '99';

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4, }}>
        <Typography variant="h2" component="h1" gutterBottom align="center" style={{ fontFamily: 'RobotoSlab', color: '#7e3e1b', }}>
          Our Pricing Plans
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center" style={{ fontFamily: 'RobotoSlab', color: '#7e3e1b', }}>
          Choose the plan that fits your needs
        </Typography>
      </Box>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card style={{ maxWidth: 350, backgroundColor: '#FCF8F3', margin: 'auto', textAlign: 'center', borderRadius: '15px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom style={{ fontFamily: 'RobotoSlab', }}>
                Unlimited Scans
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <Typography style={{ fontFamily: 'RobotoSlab', }}>Monthly</Typography>
                <CustomSwitch checked={props.isYearly} onChange={handleToggle} style={{ color: '#7e3e1b', }} />
                <Typography style={{ fontFamily: 'RobotoSlab', }}>Yearly</Typography>
              </Box>
              
              {props.isYearly && <Typography style={{ fontFamily: 'RobotoSlab', fontSize: 14, }}>* billed yearly</Typography>}
              <Typography style={{ color: '#7e3e1b', fontFamily: 'RobotoSlab', fontSize: '2.5rem', fontWeight: 'bold', marginBottom: 5, }}>
                ${price}<Typography component="span" variant="h6">/month</Typography>
              </Typography>
              <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                <CheckCircleIcon style={{ color: '#7e3e1b', }} sx={{ mr: 1 }} /> Unlimited scans
              </Typography>
              <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                <CheckCircleIcon style={{ color: '#7e3e1b', }} sx={{ mr: 1 }} /> Priority support
              </Typography>
              <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                <CheckCircleIcon style={{ color: '#7e3e1b', }} sx={{ mr: 1 }} /> Advanced analytics
              </Typography>
              <Button
                variant="contained"
                disabled={props.isAuth}
                onClick={() => {
                  props.setIsFlipped(true);
                  navigate('/login');
                }}
                style={{
                  textTransform: 'none',
                  fontFamily: 'RobotoSlab',
                  color: '#FCF8F3',
                  borderRadius: '20px',
                  backgroundColor: props.isAuth ? '#9e9e9e' : '#7e3e1b',
                  border: '1px solid #FCF8F3',
                  marginTop: 15
                }}
              >
                {props.isAuth ? 'Current Plan' : 'Get Started'}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="body1" gutterBottomstyle={{ fontFamily: 'RobotoSlab', }}>
          All plans come with a 30-day money-back guarantee.
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'RobotoSlab', }}>
          Have questions? Contact our sales team for more information.
        </Typography>
      </Box>
    </Container>
  );
};

export default Pricing;