import { Stack, styled, TextField, InputAdornment, Box, Button, Modal, Typography, Tooltip, tooltipClasses, FormControlLabel, Checkbox, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DoneIcon from '@mui/icons-material/Done';
import ModalForSettings from "./ModalForSettings";
import { utils, writeFile } from 'xlsx';
import { BorderBottom } from "@mui/icons-material";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const CostumTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCF8F3',
    color: '#7e3e1b',
    maxWidth: "10vw",
    border: '2px solid #7e3e1b',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#7e3e1b',
  },
}));

function FilterBar(props) {
  const [isFilter, setIsFilter] = useState(true); // TODO: replace back to false

  const [modalSetting, setModalSetting] = useState("");
  const [openModalSetting, setOpenModalSetting] = useState(false);
  const [openBsrModal, setOpenBsrModal] = useState(false);
  const [openMoreSettingsModal, setOpenMoreSettingsModal] = useState(false);
  // const [searchText, setSearchText] = useState(props.filters.hasOwnProperty("search") ? props.filters.search : "");
  const [categories, setCategories] = useState({
    "Kitchen & Dining": false,
    "Beauty & Personal Care": false,
    "Toys & Games": false,
    "Office Products": false,
    "Home & Kitchen": false,
    "Baby": false,
    "Unique Finds": false,
    "Sports & Outdoors": false,
    "Patio, Lawn & Garden": false,
    "Arts, Crafts & Sewing": false,
    "Tools & Home Improvement": false,
    "Automotive": false,
    "Industrial & Scientific": false,
    "Video Games": false,
    "Health & Household": false,
    "Our Brands": false,
    "Cell Phones & Accessories": false,
    "Pet Supplies": false,
    "Books": false,
    "Musical Instruments": false,
    "Clothing, Shoes & Jewelry": false,
    "Grocery & Gourmet Food": false,
    "Climate Pledge Friendly": false,
    "Electronics": false,
    "Amazon Renewed": false,
    "Amazon Devices & Accessories": false,
    "Computers & Accessories": false,
    "Appliances": false,
    "CDs & Vinyl": false,
    "Software": false,
    "Handmade Products": false,
    "Collectible Coins": false,
    "Movies & TV": false,
    "Camera & Photo Products": false,
  });
  const [selectAll, setSelectAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State to track menu visibility
  const [searchTextIndex, setSearchTextIndex] = useState(0);
  const [saveFiltersSlot, setSaveFiltersSlot] = useState(1);
  const [manualRender, setManualRender] = useState(15);
  const searchTextsPlaceholders = ['', 'P', 'Pr', 'Pro', 'Prod', 'Produ', 'Produc', 'Product', 'Product.', 'Product..', 'Product...',
    '', 'A', 'AS', 'ASI', 'ASIN', 'ASIN.', 'ASIN..', 'ASIN...',
    '', 'B', 'Br', 'Bra', 'Bran', 'Brand', 'Brand.', 'Brand..', 'Brand...']

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSearchTextIndex((prevIndex) => (prevIndex + 1) % searchTextsPlaceholders.length);
    }, 350);

    return () => clearInterval(intervalId);
  });

  const toggleCategory = (name) => {
    if (name === 'all') {
      const newSelectAll = !selectAll;
      setCategories((prevCategories) =>
        Object.fromEntries(
          Object.keys(prevCategories).map((key) => [key, newSelectAll])
        )
      );
      setSelectAll(newSelectAll); // Update the selectAll state
    } else {
      setCategories((prevCategories) => ({
        ...prevCategories,
        [name]: !prevCategories[name],
      }));
    }
  };

  const handleClickCategory = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleCloseCategory = () => {
    props.setFilters((filters) => ({
      ...filters,
      category: Object.keys(categories).sort().filter(key => categories[key] === true),
    }))
    if (Object.keys(categories).sort().filter(key => categories[key] === true).length === 0) {
      let tempFilters = { ...props.filters };
      delete tempFilters['category'];
      props.setFilters(tempFilters);
    }

    setAnchorEl(null); // Close the menu
  };

  const exportToExcel = () => {
    let formedData = []
    props.data.forEach(product => {
      formedData.push({
        "1. ASIN": product.asin,
        "2. UPC": '="' + (product.Barcode ? product.Barcode : product.upcs) + '"',
        "3. Name": product.title,
        "4. Category": product.category,
        "5. BSR": product.sales_rank,
        "6. AZ Offers": product.total_offers_count,
        "7. Supplier Price": product.price,
        "8. AZ Price": product.az_price,
        "9. FBA Cost": product.fba_fee,
        "10. AZ Referral Fee": product.az_price * product.ref_fee_perc,
        "11. ROI": (((product.az_price -
          (product.price * (1 - (props.filters.hasOwnProperty("priceCoupon") && (props.filters["priceCoupon"] / 100)))) -
          (product.ref_fee_perc * product.az_price) - (!props.filters.isFBM && product.fba_fee) -
          parseFloat(localStorage.getItem("costPrep"))) /
          ((product.price * (1 - (props.filters.hasOwnProperty("priceCoupon") && (props.filters["priceCoupon"] / 100)))) + parseFloat(localStorage.getItem("costPrep")))) *
          100
        ).toFixed(2),
        "12. Estimated Sales": product.keepa_monthly_sold > 0 ? ("Verified: " + product.keepa_monthly_sold + "+") : product.estimate_sales_30_days
      })
    });
    const ws = utils.json_to_sheet(formedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet 1');
    writeFile(wb, 'Aniseller.csv');
  };

  const filterBtnStyle = {
    textTransform: 'none',
    fontFamily: 'RobotoSlab',
    color: '#7e3e1b',
    borderRadius: '20px 20px 10px 10px',
    // backgroundColor: '#FCF8F3',
    width: '7vw',
    border: '1px solid #7e3e1b',
    borderWidth: '0px 0px 2px 0px',
  }

  return (
    <div className="filterBar">
      <div onClick={() => setIsFilter(!isFilter)} style={{ cursor: 'pointer', maxWidth: '6vw', fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
        {isFilter ? <FilterListOffIcon /> : <FilterListSharpIcon />}&nbsp;FILTERS
        {/* {isFilter ? <FilterListOffIcon /> : <FilterListSharpIcon />}FILTERS */}
      </div>
      {isFilter && (
        <div className="products-table-container">
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" style={{ marginBottom: 15 }}>
            {/* Export button */}
            <Button
              variant="contained"
              onClick={() => {
                if (props.activePlanLevel !== "Pro Plan" && props.activePlanLevel !== "Master Plan") {
                  alert("Only Pro & Master Plan subscribers can access this feature!");
                } else {
                  exportToExcel();
                }
              }}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#7e3e1b',
                width: '7vw',
                border: '2px solid #FCF8F3',
              }}
            >
              Export <FileDownloadOutlinedIcon style={{ color: '#FCF8F3' }} />
            </Button>

            <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }} style={{ justifyContent: 'center'}}>

              {/* Filter out if Amazon is one of the sellers */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.filters && props.filters.amazonNotSeller || false}
                    sx={{ color: '#7e3e1b', '&.Mui-checked': { color: '#7e3e1b' } }}
                    onChange={(e) =>
                      props.setFilters((filters) => ({
                        ...filters,
                        amazonNotSeller: +e.target.checked,
                      }))
                    }
                  />
                }
                label={<strong style={{ fontFamily: 'RobotoSlab' }}>Hide if Amazon is a seller</strong>}
              />

              {/* Must have no variations */}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={props.filters && props.filters.noVariations || false}
                    sx={{ color: '#7e3e1b', '&.Mui-checked': { color: '#7e3e1b' } }}
                    onChange={(e) =>
                      props.setFilters((filters) => ({
                        ...filters,
                        noVariations: +e.target.checked,
                      }))
                    }
                  />
                }
                label={<span style={{ fontFamily: 'RobotoSlab' }}>Must have no variations</span>}
              /> */}

              {/* No Private Label */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.filters && props.filters.hasOwnProperty("offers") && props.filters['offers'][0] >= 4}
                    sx={{ color: '#7e3e1b', '&.Mui-checked': { color: '#7e3e1b' } }}
                    onChange={(e) =>
                      props.setFilters((filters) => ({
                        ...filters,
                        offers:
                          props.filters &&
                            props.filters.hasOwnProperty("offers") &&
                            props.filters['offers'][0] >= 4
                            ? [-1, -1]
                            : [4, -1],
                      }))
                    }
                  />
                }
                label={
                  <CostumTooltip
                    arrow
                    placement="top-start"
                    title={
                      <React.Fragment>
                        <Typography
                          color="#7e3e1b"
                          style={{
                            fontWeight: 'bold',
                            fontSize: 12,
                            textAlign: 'center',
                            fontFamily: 'RobotoSlab',
                          }}
                        >
                          The listing must have<br />4+ Amount of sellers.
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <span style={{ fontFamily: 'RobotoSlab' }}>
                      At least 4+ Sellers<sub>(?)</sub>
                    </span>
                  </CostumTooltip>
                }
              />

              {/* Filter out if Amazon is one of the sellers */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!props.filters.hasOwnProperty("isFBM") || !props.filters.isFBM}
                    sx={{ color: '#7e3e1b', '&.Mui-checked': { color: '#7e3e1b' } }}
                    onChange={(e) =>
                      props.setFilters((filters) => ({
                        ...filters,
                        isFBM: filters.hasOwnProperty("isFBM") ? !filters.isFBM : true,
                      }))
                    }
                  />
                }
                label={
                  <span style={{ fontFamily: 'RobotoSlab' }}>
                    Include FBA Fees in Amazon Fees
                  </span>
                  }
              />
            </Stack>

            {/* VLookup button */}
            <Button
              variant="contained"
              onClick={() => alert('need to fix')}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#7e3e1b',
                border: '1px solid #FCF8F3',
              }}
            >
              VLookup - {props.data[0]?.Barcode ? "ASINs" : "UPCs"}
            </Button>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
              { /* Reset Filters */}
              <CostumTooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'RobotoSlab', }}>Reset Filters</Typography>
                  </React.Fragment>
                }
              >
                <FilterAltOffIcon onClick={() => props.setFilters({ isFBM: true, })} style={{ fontSize: 36, cursor: "pointer" }}/>
              </CostumTooltip>

              {/* Search input */}
              {/* <TextField
                sx={{ width: '12vw', color: '#7e3e1b', }}
                variant="outlined"
                placeholder={`Search ${searchTextsPlaceholders[searchTextIndex]}`}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" color='#7e3e1b'>
                      <SearchSharpIcon />
                    </InputAdornment>
                  ),
                }}
              /> */}

              {/* Categories select */}
              <Box sx={{ fontSize: 13 }}>
                <Button
                  onClick={handleClickCategory}
                  style={{ ...filterBtnStyle, }}
                >
                  <ArrowRightIcon />
                  <span>Category</span>
                </Button>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseCategory}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  style: {
                    maxHeight: 350, // Set a maximum height
                  },
                }}
              >
                <MenuItem onClick={() => toggleCategory('all')} style={{ display: 'flex', flexDirection: 'row', gap: '16px', listStyleType: 'none', padding: 0, cursor: 'pointer', }}>
                  <Box
                    component={selectAll ? DoneIcon : 'span'}
                    sx={{ width: 14, height: 14, flexShrink: 0, borderRadius: '3px', ml: 1, mt: '2px', }}
                    style={{ border: '1px solid #7e3e1b', color: '#7e3e1b', }}
                  />
                  <Box><span style={{ color: '#7e3e1b' }}>{selectAll ? 'Unselect All' : 'Select All'}</span></Box>
                </MenuItem>
                {Object.keys(categories).map((name, key) => (
                  <MenuItem key={key} onClick={() => toggleCategory(name)} style={{ display: 'flex', flexDirection: 'row', gap: '16px', listStyleType: 'none', padding: 0, cursor: 'pointer', }}>
                    <Box
                      component={categories[name] ? DoneIcon : 'span'}
                      sx={{ width: 14, height: 14, flexShrink: 0, borderRadius: '3px', ml: 1, mt: '2px', }}
                      style={{ border: '1px solid #7e3e1b', color: '#7e3e1b', }}
                    />
                    <Box><span style={{ color: '#7e3e1b' }}>{name}</span></Box>
                  </MenuItem>
                ))}
              </Menu>

              {/* Price */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("price"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    // border: props.filters && props.filters.hasOwnProperty("price") ? "3px solid rgb(72,199,142)" : '2px solid #7e3e1b',
                  }}
                ><span>Price</span>
                </Button>
              </Box>

              {/* Profit */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("profit"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    // border: props.filters && props.filters.hasOwnProperty("profit") ? "3px solid rgb(72,199,142)" : '2px solid #7e3e1b',
                  }}
                ><span>Profit</span>
                </Button>
              </Box>

              {/* ROI */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("roi"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    // border: props.filters && props.filters.hasOwnProperty("roi") ? "3px solid rgb(72,199,142)" : '2px solid #7e3e1b',
                  }}
                ><span>ROI</span>
                </Button>
              </Box>

              {/* BSR */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => setOpenBsrModal(true)}
                  style={{
                    ...filterBtnStyle,
                    // border: props.filters && (props.filters.hasOwnProperty("bsr") || props.filters.hasOwnProperty("top_bsr")) ? "3px solid rgb(72,199,142)" : '2px solid #7e3e1b',
                  }}
                ><span>BSR</span>
                </Button>
              </Box>

              {/* AZOffers */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("offers"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    width: '8vw',
                    // border: props.filters && props.filters.hasOwnProperty("offers") ? "3px solid rgb(72,199,142)" : '2px solid #7e3e1b',
                  }}
                ><span>Amount of Sellers</span>
                </Button>
              </Box>

              {/* BSR Drops */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("bsr_drops_30_days"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    // border: props.filters && props.filters.hasOwnProperty("bsr_drops_30_days") ? "3px solid rgb(72,199,142)" : '2px solid #7e3e1b',
                  }}
                ><span>BSR Drops</span>
                </Button>
              </Box>

              {/* Est. Sales */}
              <Box sx={{ fontSize: 13 }}>
                <Button onClick={() => { setModalSetting("sales"); setOpenModalSetting(true); }}
                  style={{
                    ...filterBtnStyle,
                    // border: props.filters && props.filters.hasOwnProperty("sales") ? "3px solid rgb(72,199,142)" : '2px solid #7e3e1b',
                  }}
                ><span>Est. Sales</span>
                </Button>
              </Box>
            </Stack>

            {/* More... */}
            <Button
              variant="contained"
              onClick={() => setOpenMoreSettingsModal(true)}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#7e3e1b',
                width: '7vw',
                border: '2px solid #FCF8F3',
              }}
            ><span>More</span>
            </Button>
          </Stack>
        </div>
      )}

      <ModalForSettings
        filters={props.filters}
        setFilters={props.setFilters}
        modalSetting={modalSetting}
        setModalSetting={setModalSetting}
        isMobile={props.isMobile}
        supplierType={"retail"}
        open={openModalSetting}
        setOpen={setOpenModalSetting}
      />

      <Modal open={openBsrModal} onClose={() => setOpenBsrModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            BSR Type
          </Typography>

          {/* BSR types select buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <CostumTooltip
              arrow
              placement="top-end"
              title={
                <React.Fragment>
                  <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'RobotoSlab', }}>Sales Rank Number</Typography>
                </React.Fragment>
              }
            >
              <Button variant="contained" onClick={() => { setModalSetting("bsr"); setOpenModalSetting(true); setOpenBsrModal(false); }} style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#7e3e1b',
                borderRadius: '20px',
                backgroundColor: '#FCF8F3',
                border: '1px solid #7e3e1b'
              }} >
                Sales Rank #
              </Button>
            </CostumTooltip>
            <CostumTooltip
              arrow
              placement="top-start"
              title={
                <React.Fragment>
                  <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'RobotoSlab', }}>% / Max Sales Rank</Typography>
                </React.Fragment>
              }
            >
              <Button variant="contained" onClick={() => { setModalSetting("top_bsr"); setOpenModalSetting(true); setOpenBsrModal(false); }} style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#7e3e1b',
                borderRadius: '20px',
                backgroundColor: '#FCF8F3',
                border: '1px solid #7e3e1b'
              }} >
                Top BSR %
              </Button>
            </CostumTooltip>
          </Box>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => setOpenBsrModal(false)} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3'
            }} >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openMoreSettingsModal} onClose={() => setOpenMoreSettingsModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            Advanced Settings
          </Typography>

          <div style={{ border: "1px solid gray", paddingTop: "15px", paddingBottom: "15px", }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
              <button style={{ backgroundColor: "transparent", borderRadius: "50%", cursor: saveFiltersSlot !== 1 && "pointer" }} disabled={saveFiltersSlot === 1} onClick={() => setSaveFiltersSlot(1)}>1</button>&emsp;
              <button style={{ backgroundColor: "transparent", borderRadius: "50%", cursor: saveFiltersSlot !== 2 && "pointer" }} disabled={saveFiltersSlot === 2} onClick={() => setSaveFiltersSlot(2)}>2</button>&emsp;
              <button style={{ backgroundColor: "transparent", borderRadius: "50%", cursor: saveFiltersSlot !== 3 && "pointer" }} disabled={saveFiltersSlot === 3} onClick={() => setSaveFiltersSlot(3)}>3</button>&emsp;
              <button style={{ backgroundColor: "transparent", borderRadius: "50%", cursor: saveFiltersSlot !== 4 && "pointer" }} disabled={saveFiltersSlot === 4} onClick={() => setSaveFiltersSlot(4)}>4</button>&emsp;
              <button style={{ backgroundColor: "transparent", borderRadius: "50%", cursor: saveFiltersSlot !== 5 && "pointer" }} disabled={saveFiltersSlot === 5} onClick={() => setSaveFiltersSlot(5)}>5</button>
            </Box>

            <p style={{ width: "60%", overflowY: "scroll", backgroundColor: "rgba(0,0,0,0.2)", minHeight: manualRender + "px", maxHeight: "10vh", textAlign: "center", margin: "auto", marginTop: "5px" }}>
              {(localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) !== null ?
                localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) : "").replace("{", "").replace("}", "").replaceAll('"', '').replaceAll(',', ', ').replace("search:,", "").replace("moq:[-1, -1],", "").toLowerCase().split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1).replace(":", ": ")).join(' ').replaceAll("-1", "N/A")}
            </p>

            {/* Save & Load buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.setItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : ""), JSON.stringify(props.filters));
                  setManualRender(mr => mr + 1);
                }}
                style={{
                  textTransform: 'none',
                  fontFamily: 'RobotoSlab',
                  color: '#FCF8F3',
                  borderRadius: '20px',
                  backgroundColor: '#769358',
                  border: '1px solid #FCF8F3'
                }} >
                Save Filters
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) !== null
                    && props.setFilters(JSON.parse(localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : ""))));
                }}
                style={{
                  textTransform: 'none',
                  fontFamily: 'RobotoSlab',
                  color: '#4A4A4A',
                  borderRadius: '20px',
                  backgroundColor: '#F8DE7E',
                  border: '1px solid #4A4A4A'
                }} >
                Load Filters
              </Button>
            </Box>
          </div>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => setOpenMoreSettingsModal(false)} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3'
            }} >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default FilterBar;
