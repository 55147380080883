import { getUser } from "./authService";

export default async function addScanedFile(fileName, lineCount, identifier) {
  const curr_scan = {fileName: fileName, lineCount: lineCount, identifier: identifier, date: new Date().toLocaleString()};
  const email = getUser();

  const obj = {email: email, curr_scan: curr_scan};

  await fetch("http://127.0.0.1:5000/aniseller_update_scans/", { // https://server.nepeto.com
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj)
  })
}

export async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export async function addToFavoritesDB(product) {
  const favProduct = {...product, date: new Date().toLocaleDateString()};
  const email = getUser();

  const obj = {email: email, favProduct: favProduct};

  console.log(obj)
  await fetch("http://127.0.0.1:5000/aniseller_add_to_favorites/", { // https://server.nepeto.com
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj)
  })
}

export async function removeFromFavoritesDB(asin) {
  const email = getUser();

  const response = await fetch(`http://127.0.0.1:5000/aniseller_remove_from_favorites/${email}/${asin}/`); // https://server.nepeto.com
  const resData = await response.json();
  console.log(resData);
}